<script>
import { BCol } from 'bootstrap-vue';

export default {
    name: 'PaynetIndex',
    components: { BCol },
    data: () => ({}),
    computed: {
        tabsNavlinks() {
            return [
                {
                    route: {
                        name: 'paynet Statistics'
                    },
                    title: 'Statistics'
                },
                {
                    route: {
                        name: 'paynet Services'
                    },
                    title: 'Services'
                },
                {
                    route: {
                        name: 'paynet Transactions'
                    },
                    title: 'Transactions'
                }
            ];
        }
    }
};
</script>

<template>
    <div>
        <b-col cols="12">
            <div class="nav nav-tabs">
                <router-link
                    :to="link.route"
                    class="nav-link"
                    v-for="(link, index) in tabsNavlinks"
                    :key="index"
                    active-class="active"
                    v-if="hasAccess(link.section, link.permission)"
                >
                    {{ link.title }}
                </router-link>
            </div>
        </b-col>

        <router-view />
    </div>
</template>

<style scoped></style>
